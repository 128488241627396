import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"

const Home = () => {
  return (
    <Layout>
      <section className="welcome__banner">
        <h1 className="header__welcome">
          Welcome to
          <mark className="header__country"> Canada!</mark>
        </h1>
        <Link className="btn btn--primary header__button" to={"/welcome/"}>
          We Are Called to Welcome
        </Link>
        <Link className="btn btn--primary header__button" to={"/sponsorship/"}>
          Refugee Sponsorship
        </Link>
        <Link className="btn btn--primary header__button" to={"/claimants/"}>
          Refugee Claimants in Canada
        </Link>
        <Link className="btn btn--primary header__button" to={"/settling/"}>
          Settling in Canada
        </Link>
        <Link className="btn btn--primary header__button" to={"/map/"}>
          Resource Map
        </Link>
      </section>
      <div className="index__picture" />
      <section className="index__section">
        <div className="blurb">
          <h4 className="index__blurb-title">Who We Are</h4>
          <p>
            <strong>Welcome CanRefugee</strong> provides information for
            churches, refugees, and others interested in learning how to support
            refugees. Here you will learn about the different ways that refugees
            come to Canada along with links to some of the best resources to
            help support newly arrived refugees here in Canada.
          </p>
          <p>
            We are part of{" "}
            <a
              href="https://welcomechurch.ca/"
              target="_blank"
              rel="noreferrer"
            >
              Welcome Church Canada
            </a>{" "}
            - a network of churches across Canada who have training and are
            ready to welcome newcomers into their community. Welcome Church is
            an initiative of{" "}
            <a
              href="https://www.ourcommoncalling.ca/"
              target="_blank"
              rel="noreferrer"
            >
              Our Common Calling
            </a>
            .
          </p>
          <p>
            <strong>
              Please note that Welcome CanRefugee is NOT a sponsoring agency
              (SAH) and cannot help bring people to Canada.
            </strong>
          </p>
        </div>
        <div className="blurb">
          <p>
            <strong>Our world is facing a global refugee crisis.</strong> War
            and persecution is creating the largest number of refugees in
            recorded history. Over 82 million people have been forced to flee
            their homes because of that.
          </p>
          <p>
            Many of these uprooted people come to Canada seeking safety and
            protection. <strong>You can make a difference!</strong>
          </p>
          <p>
            Churches are uniquely positioned to offer relational and practical
            support to people seeking safety, peace and a new home here in
            Canada.
          </p>
          <p>
            Click{" "}
            <a
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/canada-role.html"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about how Canada&apos;s refugee system works.
          </p>
        </div>
      </section>
      <div className="scripture">
        <i>
          The foreigner residing among you must be treated as your native-born.
          Love them as yourself.
          <br />
          Leviticus 19:34
        </i>
      </div>
    </Layout>
  )
}

export default Home
